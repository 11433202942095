import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { ImageCarouselModalData } from '@/pages/backoffice/Modals/command';
import { IInvoice, IPaymentRequestFile, IQuote } from '@/pages/backoffice/Requests/types';
import Button from '@core-ui/button';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import { DownloadSimple } from '@phosphor-icons/react';
import { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';

const imageStyles: CSSProperties = {
  height: '100%',
  width: '100%',
  minHeight: '56px',
  objectFit: 'cover',
  cursor: 'pointer',
};

const buttonStyles: SxProps<Theme> = {
  position: 'absolute',
  right: 4,
  top: 4,
};

interface IProps {
  document: IQuote | IInvoice | IPaymentRequestFile;
}

const SourceFileRoot = (props: IProps) => {
  const { document } = props;

  const dispatch = useDispatch();

  const files = 'files' in document ? document.files : document.child_files;

  const handleDownloadClick = (file: IPaymentRequestFile) => () => {
    window.open(file.link);
  };

  const handleImageClick = (idx: number) => () => {
    const imageFiles = files?.map((file) => file.link) ?? [];

    dispatch(openBackofficeModal(new ImageCarouselModalData(imageFiles, idx, undefined, document.id)));
  };

  return (
    <Grid item container gap={3}>
      {files?.map((file, idx) => (
        <Grid item key={file.link} maxHeight={300} width={200} position="relative">
          <Button
            color="black"
            variant="contained"
            icon={<DownloadSimple weight="bold" />}
            onClick={handleDownloadClick(file)}
            sx={buttonStyles}
          />

          <img src={file.link} style={imageStyles} alt={file.filename} onClick={handleImageClick(idx)} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SourceFileRoot;
