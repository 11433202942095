import ItemExpanded from '@/pages/backoffice/Requests/SingleRequest/components/ItemExpanded';
import QuoteButtons from '@/pages/backoffice/Requests/SingleRequest/QuotesList/components/QuoteButtons';
import QuoteRow from '@/pages/backoffice/Requests/SingleRequest/QuotesList/components/QuoteRow';
import { IQuote } from '@/pages/backoffice/Requests/types';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import useStyles from './styles';

interface IProps {
  document: IQuote;
  checked?: boolean;
  isCompare?: boolean;
}

const QuoteItem = (props: IProps) => {
  const { document, checked, isCompare } = props;
  const [expanded, setExpanded] = useState(false);
  const styles = useStyles({ expanded });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item container xs={12} sx={styles.root}>
      <QuoteRow
        document={document}
        expanded={expanded}
        onExpand={handleExpandClick}
        checked={checked}
        isCompare={isCompare}
      />

      <ItemExpanded expanded={expanded} document={document} />

      {!isCompare && <QuoteButtons document={document} />}
    </Grid>
  );
};

export default QuoteItem;
