import { exhaustiveGuard } from '@/app/utils/app';
import DigitizedDocumentRoot from '@/pages/backoffice/Requests/SingleRequest/components/ItemTools/tabs/DigitizedDocumentRoot';
import SourceFileRoot from '@/pages/backoffice/Requests/SingleRequest/components/ItemTools/tabs/SourceFileRoot';
import { QUOTE_TAB_ENUM } from '@/pages/backoffice/Requests/SingleRequest/QuotesList/consts';
import { IInvoice, IQuote } from '@/pages/backoffice/Requests/types';
import { COLOURS } from '@core-ui/styles';
import Tabs from '@core-ui/tabs';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'components/Intl';
import { useState } from 'react';

const makeTabs = (document: IQuote | IInvoice) => [
  {
    label: <FormattedMessage id="tabs.digitized_document" />,
    value: QUOTE_TAB_ENUM.DIGITIZED_DOCUMENT,
  },
  {
    label: <FormattedMessage id="tabs.source_file" />,
    value: QUOTE_TAB_ENUM.SOURCE_FILE,
    disabled: document.files.length === 0,
  },
];

interface IProps {
  document: IQuote | IInvoice;
}

const ItemTools = (props: IProps) => {
  const { document } = props;

  const tabs = makeTabs(document);
  const [tab, setTab] = useState(QUOTE_TAB_ENUM.DIGITIZED_DOCUMENT);

  const handleChange = (tab: string) => {
    setTab(tab as QUOTE_TAB_ENUM);
  };

  const renderTab = (tab: QUOTE_TAB_ENUM) => {
    switch (tab) {
      case QUOTE_TAB_ENUM.DIGITIZED_DOCUMENT:
        return <DigitizedDocumentRoot document={document} />;
      case QUOTE_TAB_ENUM.SOURCE_FILE:
        return <SourceFileRoot document={document} />;
      default:
        return exhaustiveGuard(tab);
    }
  };

  return (
    <Box width="100%" paddingTop={3} borderTop={`1px dashed ${COLOURS.BORDER.MAIN}`}>
      <Box height={40} marginBottom={5} bgcolor={COLOURS.BACKGROUND.DISABLED} borderRadius={1} padding="0 16px">
        <Tabs tabs={tabs} active={tab} onChange={handleChange} />
      </Box>

      {renderTab(tab)}
    </Box>
  );
};

export default ItemTools;
