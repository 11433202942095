import { COLOURS } from '@core-ui/styles';
import { IMenuOption } from '@/components/Menu/Menu';
import MoreButton from '@/components/MoreButton';
import { WalletOutputSchema } from '@/generated';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { CreateEditWalletModalData, DeleteWalletModalData } from '@/pages/backoffice/Modals/command';
import { NotePencil, Trash } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';

interface IProps {
  wallet: WalletOutputSchema;
}

const SingleWalletHeaderMoreButton = (props: IProps) => {
  const { wallet } = props;

  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(openBackofficeModal(new CreateEditWalletModalData(wallet, false)));
  };

  const handleDelete = () => {
    dispatch(openBackofficeModal(new DeleteWalletModalData(wallet.id, true)));
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.edit" />,
      dataTestId: 'single-wallet-more-button-edit',
      icon: <NotePencil />,
      onClick: handleEdit,
      visible: true,
    },
    {
      title: <FormattedMessage id="label.delete" />,
      dataTestId: 'single-wallet-more-button-delete',
      textColor: COLOURS.Strawberry.Solid[600],
      icon: <Trash color={COLOURS.Strawberry.Solid[600]} />,
      onClick: handleDelete,
      visible: true,
    },
  ];

  return <MoreButton options={options} variant="square" padding={4} dataTestId="single-wallet-header-more-button" />;
};

export default SingleWalletHeaderMoreButton;
