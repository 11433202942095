import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

const SingleWalletRootSkeleton = () => {
  return (
    <Grid container direction="column" gap={6} padding={6}>
      <Grid container direction="column" gap={2} marginBottom="1px">
        <Skeleton variant="rounded" height={26} width={128} />
        <Skeleton variant="rounded" height={26} width={260} />
        <Skeleton variant="rounded" height={26} width={200} />
      </Grid>

      <Skeleton variant="rounded" height={118} width="100%" />

      <Grid item container gap={2} marginBottom="1px">
        <Skeleton variant="rounded" height={48} width={202} sx={{ bgcolor: COLOURS.WHITE, marginRight: 1 }} />

        <Skeleton variant="rounded" height={48} width={136} sx={{ bgcolor: COLOURS.WHITE }} />
        <Skeleton variant="rounded" height={48} width={136} sx={{ bgcolor: COLOURS.WHITE, marginRight: 1 }} />

        <Skeleton variant="rounded" height={48} width={308} sx={{ bgcolor: COLOURS.WHITE, marginRight: 1 }} />

        <Skeleton variant="rounded" height={48} width={308} sx={{ bgcolor: COLOURS.WHITE }} />
      </Grid>
    </Grid>
  );
};

export default SingleWalletRootSkeleton;
