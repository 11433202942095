import { TableCell, TableRow } from '@core-ui/table';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ROUTES } from '@/app/consts/routes';
import { AmountFormatter, CurrencyCodeGetterById } from '@/app/types/common';
import { CashPaymentTypeEnum, WalletOutputSchema } from '@/generated';
import history from '@/history';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import WalletsRowMoreButton from '@/pages/backoffice/Wallets/WalletsList/WalletsRowMoreButton';
import { ArrowSquareOut, Bank, Coins, CreditCard } from '@phosphor-icons/react';
import capitalize from 'lodash-es/capitalize';
import React from 'react';
import IconButtonWithTooltip from '@/components/IconButtonWithTooltip';
import useStyles from './styles';

interface IProps {
  item: WalletOutputSchema;
  amountFormatter: AmountFormatter;
  getCurrencyCode: CurrencyCodeGetterById;
}

const WalletsRow = (props: IProps) => {
  const { item, amountFormatter, getCurrencyCode } = props;

  const styles = useStyles();

  const handleOpenWallet = () => {
    history.push(buildURL(`/${item.boat_id}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}/${item.id}`));
  };

  let typeIcon: JSX.Element;

  switch (item.wallet_type) {
    case CashPaymentTypeEnum.CASH:
      typeIcon = <Coins />;
      break;
    case CashPaymentTypeEnum.CREDIT_CARD:
      typeIcon = <CreditCard />;
      break;
    case CashPaymentTypeEnum.WIRE_TRANSFER:
      typeIcon = <Bank />;
      break;
    default:
      typeIcon = <Coins />;
      break;
  }

  const typeTooltipTitle = capitalize(item.wallet_type.replace(/_/g, ' '));

  return (
    <TableRow>
      <TableCell padding="large" sx={styles.rowIconCell}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Tooltip title={typeTooltipTitle} placement="top" arrow>
            <SvgIcon fontSize="large" alignmentBaseline="central">
              {typeIcon}
            </SvgIcon>
          </Tooltip>
        </Grid>
      </TableCell>

      <TableCell onClick={handleOpenWallet} sx={styles.nameCell}>
        {item.name}
      </TableCell>

      <TableCell>
        <Typography fontFamily="mono">{getCurrencyCode(item.currency_id)}</Typography>
      </TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.amount)}
        </Typography>
      </TableCell>

      <TableCell padding="medium">
        <Grid container gap={2} justifyContent="flex-end" alignItems="center">
          <WalletsRowMoreButton item={item} />

          <IconButtonWithTooltip
            onClick={handleOpenWallet}
            tooltipLabel="label.open_wallet"
            icon={<ArrowSquareOut />}
          />
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default WalletsRow;
