import { myocean__schemas__files__FileSchema, RequestDetailOutputV2Schema } from '@/generated';
import { resetState, setLoading } from '@core-ui/reducers_handlers';
import { getData, setData } from '@core-ui/reducers_handlers/actions';
import { createAction } from 'redux-actions';

export const reducerName = 'requests.view_panel';

export const resetViewRequestPanelState = resetState(reducerName);
export const setSingleRequestLoading = setLoading(reducerName);
export const setSingleRequestDisabled = createAction<boolean>(`${reducerName}.SET_DISABLED`);

export interface IGetSingleRequest {
  id?: number;
}

export const getSingleRequest = getData<IGetSingleRequest>(reducerName);
export const setSingleRequest = setData<RequestDetailOutputV2Schema>(reducerName);

export interface ISetSingleRequestFromCreateEditPanel {
  request: RequestDetailOutputV2Schema;
}

export const setSingleRequestFromCreateEditPanelModule = createAction<ISetSingleRequestFromCreateEditPanel>(
  `${reducerName}.SET_SINGLE_REQUEST_FROM_CREATE_EDIT_PANEL`
);

export interface IViewRequestPanelUploadFiles {
  files: File[];
}

export const uploadFiles = createAction<IViewRequestPanelUploadFiles>(`${reducerName}.UPLOAD_FILES`);

export interface IViewRequestPanelAddFiles {
  newFiles: myocean__schemas__files__FileSchema[];
}

export const addFilesToRequest = createAction<IViewRequestPanelAddFiles>(`${reducerName}.ADD_FILES_TO_REQUEST`);

export interface IUpdatePaidStatus {
  requestId: number;
  documentId: number;
  status: boolean;
}

export const updatePaidStatus = createAction<IUpdatePaidStatus>(`${reducerName}.UPDATE_PAID_STATUS`);
