import { WalletSmall } from '@core-ui/icons';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

const NoWallets = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      marginTop={20}
    >
      <WalletSmall />

      <Grid item marginTop={8}>
        <Typography variant="h4">
          <FormattedMessage id="wallets.no_wallets" />
        </Typography>
      </Grid>

      <Grid item marginTop={2}>
        <Typography color="text.secondary">
          <FormattedMessage id="wallets.no_wallets_sub" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoWallets;
