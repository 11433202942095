import { ApproveStatusEnum } from '@/generated';
import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { CheckCircle, Prohibit } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import isNil from 'lodash-es/isNil';
import { openBackofficeModal } from 'pages/backoffice/Modals/actions';
import { ApproveDocumentModalData, DeclineDocumentModalData } from 'pages/backoffice/Modals/command';
import { PARENT_PAGE } from 'pages/backoffice/Modals/consts';
import React from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

interface IProps {
  requestId?: number;
  documentId: number;
  approveStatus?: ApproveStatusEnum;
}

const DocumentApproveButtons = (props: IProps) => {
  const { requestId, documentId, approveStatus } = props;

  const styles = useStyles({ status: approveStatus });
  const dispatch = useDispatch();

  const handleApproveDocument = () => {
    if (!isNil(documentId) && !isNil(requestId)) {
      dispatch(openBackofficeModal(new ApproveDocumentModalData(documentId, PARENT_PAGE.REQUESTS, requestId)));
    }
  };

  const handleDeclineDocument = () => {
    if (!isNil(documentId) && !isNil(requestId)) {
      dispatch(openBackofficeModal(new DeclineDocumentModalData(documentId, PARENT_PAGE.REQUESTS, requestId)));
    }
  };

  // TODO: возможно позже откажемся от этой логики, но тогда нужно будет и стили аппрув кнопок переделывать и здесь и в Леджере
  //  и логику аналогичных кнопок в Леджере тоже нужно будет менять
  const isApproveButtonsDisabled = approveStatus !== ApproveStatusEnum.AWAITING;

  return (
    <Grid item container gap={2} width="auto">
      <Tooltip
        title={
          isApproveButtonsDisabled ? (
            <FormattedMessage
              id="tooltip.document_has_already_been_approved_or_declined"
              values={{ status: approveStatus }}
            />
          ) : (
            ''
          )
        }
        placement="top"
        arrow
      >
        <div>
          <Button
            variant="outlined"
            disabled={isApproveButtonsDisabled}
            onClick={handleApproveDocument}
            sx={styles.approveButton}
            label={<FormattedMessage id="label.approve" />}
            startIcon={
              <SvgIcon
                htmlColor={
                  approveStatus === ApproveStatusEnum.DECLINED ? COLOURS.Coconut.Solid[500] : COLOURS.Avocado.Solid[600]
                }
              >
                <CheckCircle />
              </SvgIcon>
            }
          />
        </div>
      </Tooltip>

      <Tooltip
        title={
          isApproveButtonsDisabled ? (
            <FormattedMessage
              id="tooltip.document_has_already_been_approved_or_declined"
              values={{ status: approveStatus }}
            />
          ) : (
            ''
          )
        }
        placement="top"
        arrow
      >
        <div>
          <Button
            variant="outlined"
            disabled={isApproveButtonsDisabled}
            onClick={handleDeclineDocument}
            sx={styles.declineButton}
            label={<FormattedMessage id="label.decline" />}
            startIcon={
              <SvgIcon
                htmlColor={
                  approveStatus === ApproveStatusEnum.APPROVED
                    ? COLOURS.Coconut.Solid[500]
                    : COLOURS.Strawberry.Solid[600]
                }
              >
                <Prohibit />
              </SvgIcon>
            }
          />
        </div>
      </Tooltip>
    </Grid>
  );
};

export default DocumentApproveButtons;
