import { COMPACT_HEADER_HEIGHT } from '@/pages/backoffice/consts';
import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { WALLETS_PADDING_Y } from 'pages/backoffice/Wallets/consts';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    paddingY: 5,
    paddingX: 6,
    position: 'sticky',
    top: COMPACT_HEADER_HEIGHT - WALLETS_PADDING_Y,
    zIndex: 2,
    background: COLOURS.Coconut.Solid[100],
  },
});
