import { tableCellClasses, tableRowClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  head: {
    height: 38,
  },

  row: {
    [`&.${tableRowClasses.root}>.${tableCellClasses.root}.${tableCellClasses.head}`]: {
      // to prevent not yet hidden HeaderLarge from overlapping with the table header during fast scroll down
      zIndex: 3,
    },
  },
});
